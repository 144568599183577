import React, { useState } from 'react';
import { PageContainer, PageContainerProps } from './PageContainer';
import { UiKit } from '@datavant/uikit';
import { FooterModal } from './FooterModal';

export const disclosureNoticeTitle = 'Disclosure Notice';
export const termsAndConditionsTitle = 'Terms & Conditions';
export const privacyPolicyTitle = 'Privacy Policy';
export const legalDisclaimerTitle = 'Legal Disclaimer';

export const footerModalTypes = [disclosureNoticeTitle, termsAndConditionsTitle];

export const RecordHubPageFooter = (props: { dontShowModals?: boolean }): JSX.Element => {
    const [modalType, setModalType] = useState<string | undefined>(undefined);
    return (
        <UiKit.Chakra.VStack spacing={UiKit.consistency.buffer}>
            <UiKit.Chakra.HStack justify={'center'} spacing={UiKit.consistency.buffer} width={'350px'}>
                {process.env.NEXT_PUBLIC_VERSION && (
                    <UiKit.Text.BodySmall>Version: {process.env.NEXT_PUBLIC_VERSION}</UiKit.Text.BodySmall>
                )}
                <UiKit.Text.BodySmall>Contact Us: 1-800-367-1500</UiKit.Text.BodySmall>
            </UiKit.Chakra.HStack>
            {!props.dontShowModals && (
                <UiKit.Chakra.HStack
                    justify={'center'}
                    spacing={UiKit.consistency.buffer}
                    paddingBottom={'15px'}
                >
                    {footerModalTypes.map(modalText => (
                        <UiKit.Text.Clickable
                            key={modalText}
                            onClick={() => setModalType(modalText)}
                            text={modalText}
                        />
                    ))}
                    <UiKit.Text.Clickable
                        key="privacyPolicy"
                        onClick={() => window.open('https://www.datavant.com/privacy-policy', '_blank')}
                        text={privacyPolicyTitle}
                    />
                    <FooterModal
                        isOpen={modalType}
                        onClose={() => setModalType(undefined)}
                        title={modalType}
                        isLoginModal={false}
                    />
                </UiKit.Chakra.HStack>
            )}
        </UiKit.Chakra.VStack>
    );
};

export const RecordHubPageContainer = (props: PageContainerProps): JSX.Element => {
    return (
        <PageContainer {...props}>
            {props.children}
            {!props.dontShowFooter && <RecordHubPageFooter />}
        </PageContainer>
    );
};
