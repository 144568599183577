import React, { useEffect } from 'react';

import { Security } from '@okta/okta-react';
import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import fetchJson from '@datavant/idsb-shared/utils/fetchJson';
import { theme } from '../themes';
import { UiKit, UiKitContext } from '@datavant/uikit';
import { SRTopNav } from '../components/SRTopNav';
import { SRSideNav } from '../components/SRSideNav';
import { useRouter } from 'next/router';
import { TOP_NAV_HEIGHT } from '@datavant/idsb-shared/components/size';
import './global.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../components/okta/OktaWidgetStyles.css';
import Head from 'next/head';
import { getEnv, getEnvVar, ENV_VARS } from '@datavant/idsb-shared/utils/browserEnvironmentVariables';
import { datadogRum } from '@datadog/browser-rum';
import { SCRIPT } from '../pendo';
import Script from 'next/script';
import { RingCentral } from '../components/SmartRequest/RingCentral';
import { LoginModal } from '../components/SmartRequest/LoginModal';
import {
    disclosureNoticeTitle,
    privacyPolicyTitle,
    termsAndConditionsTitle,
} from '../components/SmartRequest/RecordHubPageContainer';
import { postData } from '@datavant/idsb-shared/utils';
import { SmartRequestUserData, useUser } from '../lib/user';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { OktaAuth, AuthState } from '@okta/okta-auth-js';
import pino from 'pino';
import { SessionExpiredModal } from '../components/SessionExpiredModal';
import { RefreshCompaniesCacheProvider } from '../components/SmartRequest/RefreshCompaniesProvider';
import ErpSystemMaintenancePage, { IS_SITE_SHUTDOWN_FOR_DB_MIGRATION } from './error/503';
import { currentVersionId } from '../components/SmartRequest/TOSPrivacyDisclosureText';

const PAGES_WITHOUT_NAV = [
    '/open/self-registration',
    '/open/existing-customer-migration',
    '/open/login',
    '/open/legacy-login',
];

const log = pino().child({ name: '_app' });

const onCloseLoginModal = async (
    accepted: boolean,
    smartRequestUserData: SmartRequestUserData,
    router: any
): Promise<void> => {
    if (!accepted) {
        await fetchJson('/api/logout', { method: 'POST' });
        router.push('/logout');
        router.reload();
    } else {
        await postData('api/smartrequest/updateUserDisclaimerStatus', {
            email: smartRequestUserData.email,
        });
        router.reload();
    }
};
let oktaAuth: OktaAuth = null;

const App = ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Component,
    pageProps: { ...pageProps },
}: AppProps): JSX.Element => {
    const router = useRouter();
    const isPageWithoutNav = PAGES_WITHOUT_NAV.some(url => router.asPath.includes(url));
    const { user } = useUser();
    const pageMarginTop = isPageWithoutNav ? undefined : `${TOP_NAV_HEIGHT}px`;
    const env = getEnv();
    const envvars = ENV_VARS[env];

    if (oktaAuth === null) {
        log.info('Creating OktaAuth');
        oktaAuth = new OktaAuth({
            ...envvars.okta,

            tokenManager: {
                // Set this in dev to test token refreshing
                // expireEarlySeconds: 60 * 60 - 30,
            },
        });
        oktaAuth.authStateManager.subscribe(async (authState: AuthState) => {
            const previousState: AuthState = oktaAuth.authStateManager.getPreviousAuthState();
            const accessToken = authState.accessToken?.accessToken;
            const previousToken = previousState?.accessToken?.accessToken;
            log.info({ authState, previousState, same: accessToken === previousToken }, 'ASM Update');
            if (previousToken === undefined || accessToken === undefined || accessToken === previousToken) {
                return;
            }
            log.info('ASM Sending Token');
            await postData('/api/open/token', { accessToken });
        });
    }

    if (envvars.datadog.enabled) {
        datadogRum.init({
            applicationId: 'aa381c8c-7f75-4bbb-80d4-d8a82dd7c9c4',
            clientToken: 'pubbf50ed1b09d8caf6224cf61c198ab3d0',
            site: 'ddog-gov.com',
            service: 'smartrequest',
            env: env,
            // Corresponds to the docker image tag
            version: process.env.NEXT_PUBLIC_VERSION,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 0,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask',
            // Suppresses warnings when DD tries to re-init
            // There's no way to check whether DD is already initialized before this call
            silentMultipleInit: true,
            allowedTracingUrls: envvars.datadog.allowedTracingUrls,
        });
        if (user) {
            datadogRum.setUser({ email: user.email });
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined' && typeof window['pendo'] !== 'undefined' && user) {
            // This function creates anonymous visitor IDs in Pendo unless you change
            // the visitor id field to use your app's values
            // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID
            // unless you change the account id field to use your app's values
            // Call this function after users are authenticated in your app and
            //  your visitor and account id values are available
            // Please use Strings, Numbers, or Bools for value types.
            window['pendo'].initialize({
                visitor: {
                    id: user.userId,
                    email: user.email,
                    full_name: [user.firstName ?? '', user.lastName ?? ''].join(' '),
                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                    is_smart_request_user: true,
                },

                account: {
                    id: 'smart-request',
                    // name:         // Optional
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional

                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, typeof window !== 'undefined' && typeof window['pendo'] !== 'undefined']);

    // TODO determine if this is working as intended. Not sure if it's being called
    const restoreOriginalUri = async (): Promise<void> => {
        // history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
    const disclaimerStatus = user?.disclaimerStatus;
    const disclaimerTitle = `${termsAndConditionsTitle}, ${privacyPolicyTitle}, and ${disclosureNoticeTitle}`;
    return (
        <>
            <UiKitContext.Provider value={{ useNewBranding: true }}>
                <Script src={RingCentral.ScriptURL} strategy={'lazyOnload'} onLoad={RingCentral.init} />
                <RingCentral.Button />
                <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                    <RefreshCompaniesCacheProvider>
                        <ChakraProvider resetCSS={true} theme={theme}>
                            <div
                                dangerouslySetInnerHTML={{
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    __html: SCRIPT,
                                }}
                            />
                            <Head>
                                <title>SmartRequest</title>
                            </Head>
                            {!isPageWithoutNav && <SRTopNav />}
                            <UiKit.Chakra.HStack align={'stretch'} spacing={0} mt={pageMarginTop} flex={1}>
                                {!isPageWithoutNav && <SRSideNav />}
                                <UiKit.Chakra.VStack
                                    spacing={UiKit.consistency.buffer}
                                    flex={1}
                                    align={'stretch'}
                                    justify={'flex-start'}
                                >
                                    {IS_SITE_SHUTDOWN_FOR_DB_MIGRATION ? (
                                        <ErpSystemMaintenancePage />
                                    ) : (
                                        <Component {...pageProps} />
                                    )}
                                </UiKit.Chakra.VStack>
                            </UiKit.Chakra.HStack>
                            <LoginModal
                                isOpen={
                                    user &&
                                    (!disclaimerStatus?.tnC ||
                                        !disclaimerStatus?.privacyPolicy ||
                                        !disclaimerStatus?.disclaimer ||
                                        !(disclaimerStatus?.versionId === currentVersionId))
                                }
                                onClose={(accepted: boolean) => {
                                    onCloseLoginModal(accepted, user, router).catch(error => {
                                        // TODO: Do something better
                                        console.error(error);
                                    });
                                }}
                                title={disclaimerTitle}
                            />
                            <SessionExpiredModal />
                        </ChakraProvider>
                    </RefreshCompaniesCacheProvider>
                </Security>
            </UiKitContext.Provider>
        </>
    );
};

const ldSdkClientSideId = getEnvVar(getEnv(), 'launchDarklySdkClientSideId');
export default ldSdkClientSideId === '' ? App : withLDProvider({ clientSideID: ldSdkClientSideId })(App);
