import React from 'react';
import { UiKit } from '@datavant/uikit';

export const currentVersionId = 'V2.0';

export const LegalDisclaimer = (
    <UiKit.Chakra.VStack spacing={UiKit.consistency.buffer} align={'flex-start'}>
        <UiKit.Text.BodyRegular>
            Upon submission, your request will be processed by our team. Check for status on the Request
            screen or the Request Detail screen.
        </UiKit.Text.BodyRegular>
        <UiKit.Text.BodyRegularBold>
            Thank you for using Smart Request. SmartRequest is an enhanced method for receiving requests for
            medical records that medical providers must approve and Ciox Health is not legally obligated to
            respond to requests made through SmartRequest. If after review your request does not meet the
            requirements of the request type you selected in Smart Request, Ciox Health may unilaterally
            reject the request and it must be submitted directly to the medical provider from whom you seek
            medical records. Ciox Health and the medical provider will not be responsible for any fines, late
            fees, penalties, assessments, or other monetary damages for failing to timely respond to requests
            improperly submitted through SmartRequest.
        </UiKit.Text.BodyRegularBold>
    </UiKit.Chakra.VStack>
);
export const DisclosureNotice = (
    <UiKit.Chakra.VStack alignItems={'flex-start'} spacing={UiKit.consistency.buffer}>
        <UiKit.Text.BodyRegular>
            This information has been disclosed to you from records that may be protected by federal
            confidentiality rules (42 CFR part 2). The federal rules prohibit you from making any further
            disclosure of information in this record that identifies a patient as having or having had a
            substance use disorder either directly, by reference to publicly available information, or through
            verification of such identification by another person unless further disclosure is expressly
            permitted by written consent of the individual whose information is being disclosed or as
            otherwise permitted by 42 CFR part 2. A general authorization for the release of medical or other
            information is not sufficient for this purpose (see 42 CFR § 2.31). The federal rules restrict any
            use of the information to investigate or prosecute with regard to a crime any patient with a
            substance use disorder, except as provided at 42 CFR § § 2.12(c)(5) and 2.65.
        </UiKit.Text.BodyRegular>
        <UiKit.Text.BodyRegular>
            If the enclosed record pertains to HIV/AIDs, it has been disclosed to you from records whose
            confidentiality is protected by federal and perhaps, state law, which prohibits you from making
            any further disclosure of such information without the specific consent of the person to whom such
            information pertains or as otherwise permitted by state law. A general authorization for this
            release of health or other information is not sufficient for this purpose.
        </UiKit.Text.BodyRegular>
        <UiKit.Text.BodyRegular>
            If the information requested is from a facility located within the Washington State area then this
            information will fall under the RCW 70.02.300 which states that this information has been
            disclosed to you from records whose confidentiality may be protected by state law. State law
            prohibits you from making any further disclosure of it without the specific written authorization
            of the person to whom it pertains, or as otherwise permitted by state law. A general authorization
            for the release of this protected information is not sufficient for this purpose.
        </UiKit.Text.BodyRegular>
    </UiKit.Chakra.VStack>
);

export const PrivacyPolicy = (
    <UiKit.Chakra.VStack alignItems={'flex-start'} spacing={UiKit.consistency.buffer}>
        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.Clickable
                key="privacyPolicy"
                text={'Click here to view our Privacy Policy'}
                onClick={() => window.open('https://www.datavant.com/privacy-policy', '_blank')}
            />
        </UiKit.Chakra.VStack>
    </UiKit.Chakra.VStack>
);

export const TermsAndConditions = (
    <UiKit.Chakra.VStack alignItems={'flex-start'} spacing={UiKit.consistency.buffer}>
        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyRegular>
                NOTICE: THIS IS A LEGALLY BINDING CONTRACT. PLEASE READ IT CAREFULLY. BY UPLOADING DOCUMENTS
                AND DATA TO AND ACCEPTING THE DELIVERY OF MEDICAL RECORDS THROUGH DATAVANT’S INTERNET PORTAL
                LOCATED AT www.smartrequest.com (the “Site”), YOU ACCEPT THIS AGREEMENT AND AGREE TO BE BOUND
                BY THE TERMS AND CONDITIONS. All rights reserved. By using DATAVANT’S software and services,
                you the individual end-user (hereafter referred to as “USER”), agree to the terms and
                conditions as outlined in this agreement (collectively referred to hereafter as the
                “Agreement”). By clicking on 'Accept' below USER accepts and agrees to be legally bound by the
                terms and conditions of this Agreement. DATAVANT reserves the right to modify this Agreement
                and its website (www.smartrequest.com) at any time. USER agrees that continued access or use
                of the website shall be deemed USER'S conclusive acceptance of the modifications.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                <UiKit.Chakra.OrderedList spacing={UiKit.consistency.smallBuffer}>
                    <UiKit.Chakra.ListItem>
                        <b>Authorization.</b> USER affirms that it is authorized by law or by the subject
                        patient to obtain, review and keep a copy of the subject patient's medical and other
                        related records. USER hereby authorizes DATAVANT to retrieve, obtain, store, and
                        transmit uploaded documents by USER as well as medical and other related records
                        obtained from healthcare and other service providers. USER unconditionally represents
                        and warrants to DATAVANT that USER: (i) has requisite authority to enter into this
                        Agreement, upload relevant documents to the DATAVANT portal and obtain any and all
                        medical records that USER requests; and (ii) is not seeking any records for any
                        illegal or unethical purpose.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>USER'S DATAVANT Account.</b> USER is required to create and maintain an account
                        with DATAVANT to utilize DATAVANT software and services located at
                        www.smartrequest.com (as further defined in the “Authorization” paragraph). “Software”
                        is defined as the DATAVANT online web interface and FTP server, including all
                        components thereof and all updates, patches, fixes, modifications, and enhancements
                        thereto, including releases of new versions, whether provided to USER via download,
                        automatically without additional consent or action on USER's part or otherwise, and
                        any and all accompanying documentation, files and materials. The Software and its
                        components are licensed, owned and/or operated by DATAVANT, and offered to USER
                        subject to USER's acceptance, without modification, of all the terms and conditions
                        contained herein and all other operating rules, policies and procedures that may be
                        published from time to time by DATAVANT on its website or to USER's user account. In
                        creating such an account USER is bound to the terms and conditions of this Agreement,
                        the USER agrees that each such USER account may only be used by the person/entity who
                        created and maintains the account and, to the extent the account is for an entity,
                        organization, or law firm, any members, partners, owners, employees, or agents of that
                        entity, organization, or firm. USER's user account will be available to USER online
                        through most major web browsers. USER understands and agrees that USER is responsible
                        for providing any necessary equipment or services to access the Software, including
                        internet connection services and computer equipment and programs (including web
                        browsers, etc.) capable of accessing the user account online. USER understands and
                        agrees that USER has no right to access the user account or any information or
                        documents on that user account except as set forth herein, and that DATAVANT reserves
                        the right to freeze or deny access to any user account that it believes in its sole
                        discretion is not maintained or utilized in accordance with this Agreement. USER is
                        responsible for maintaining the security of USER's login information to USER's user
                        account and agrees to not provide this information to any unauthorized users. USER
                        agrees to immediately notify DATAVANT of any unauthorized access to USER's account,
                        the Software, or any other breaches of security. To the extent that USER cancels its
                        user account with DATAVANT, all content, information, and documents maintained by
                        DATAVANT on its servers in conjunction with USER's user account will be deleted by
                        DATAVANT without notice and at any time after USER's cancellation and subject to
                        DATAVANT 's sole discretion; accordingly, USER understands and agrees that prior to
                        any such cancellation, USER will take any steps necessary to back up, copy, or
                        otherwise preserve any information, documents or content that has been created,
                        uploaded to, or maintained by DATAVANT.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Responsibility of USER.</b> USER is responsible for the accuracy and content of all
                        documents uploaded to the DATAVANT website and all data entered on the website. USER
                        warrants that all documents and information uploaded to the DATAVANT website are free
                        from viruses, malware, spyware, trojans, worms and other malicious and dangerous
                        software or programs.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Completeness and Accuracy.</b> While DATAVANT makes every effort to ensure the
                        completeness of records, it does not assume responsibility or any liability whatsoever
                        for the record review. DATAVANT shall not be responsible for the completeness of
                        information being stored for, and delivered to, USER, and shall not be liable to USER
                        for the unavailability of information requested by USER. The completeness of the
                        records received from the provider is the responsibility of the medical provider
                        itself, not DATAVANT. DATAVANT checks the records supplied or made available by the
                        medical provider and reviews the dates of service supplied by USER, and makes no
                        promise, claims or statement that the records reviewed are complete. If USER does not
                        include dates of service in the request, DATAVANT maintains that the records are
                        complete as provided by the medical provider.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <UiKit.Chakra.UnorderedList
                            styleType={'none'}
                            spacing={UiKit.consistency.smallBuffer}
                        >
                            <UiKit.Chakra.ListItem>
                                <b>
                                    Arbitration. Please read this provision carefully. It requires that any
                                    and all claims must be resolved by binding arbitration or in small claims
                                    court. These limitations apply to any claims against DATAVANT or its
                                    current, future, or former subsidiaries or affiliates, including but not
                                    limited to any claims arising out of or related to DATAVANT’s provision of
                                    release of information services to healthcare providers. Any dispute or
                                    claim relating in any way to USER’S use of any DATAVANT Service, or to any
                                    software or services licensed, sold or distributed by DATAVANT or through
                                    DATAVANT website, will be resolved by binding arbitration, rather than in
                                    court, except that USER may assert claims in small claims court if USER’S
                                    claims qualify. The Federal Arbitration Act and federal arbitration law
                                    apply to this agreement.
                                </b>
                            </UiKit.Chakra.ListItem>
                            <UiKit.Chakra.ListItem>
                                <b>
                                    There is no judge or jury in arbitration, and court review of an
                                    arbitration award is limited. However, an arbitrator can award on an
                                    individual basis the same damages and relief as a court (including
                                    injunctive and declaratory relief or statutory damages) and must follow
                                    the terms of this Agreement as a court would.
                                </b>
                            </UiKit.Chakra.ListItem>
                            <UiKit.Chakra.ListItem>
                                <b>
                                    To begin an arbitration proceeding, USER must send a letter requesting
                                    arbitration and describing USER’S claim to the DATAVANT Legal Department
                                    at 2222 W. Dunlap Avenue, Suite 250, Phoenix, AZ 85021 (or
                                    Legal@Datavant.com). The arbitration will be conducted by the American
                                    Arbitration Association (AAA) under its rules, including the AAA's
                                    Supplementary Procedures for Consumer-Related Disputes. The AAA's rules
                                    are available at www.adr.org or by calling 1-800-778-7879. Payment of all
                                    filing, administration and arbitrator fees will be governed by the AAA's
                                    rules. USER may choose to have the arbitration conducted by telephone,
                                    based on written submissions, or in person in the county where USER lives
                                    or at another mutually agreed location.
                                </b>
                            </UiKit.Chakra.ListItem>
                            <UiKit.Chakra.ListItem>
                                <b>
                                    DATAVANT and USER each agree that any dispute resolution proceedings will
                                    be conducted only on an individual basis and not in a class, consolidated
                                    or representative action. If for any reason a claim proceeds in court
                                    rather than in arbitration, DATAVANT AND USER each waive any right to a
                                    jury trial. DATAVANT AND USER also both agree that either DATAVANT OR USER
                                    may bring suit in court to enjoin infringement or other misuse of
                                    intellectual property rights.
                                </b>
                            </UiKit.Chakra.ListItem>
                        </UiKit.Chakra.UnorderedList>
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Class Actions.</b> USER agrees that it is prohibited from initiating or joining any
                        class action proceeding against DATAVANT and its affiliates, related to this offering
                        of software, products, or services through the DATAVANT website and under this
                        AGREEMENT. Any and all proceedings to resolve claims will be conducted only on an
                        individual basis and in accordance with arbitration provision contained herein. In
                        addition, no arbitration proceeding under this Agreement shall be consolidated or
                        joined in any way with any other arbitration proceeding without the express written
                        consent of all the parties.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Confidentiality.</b> "Confidential Information" as used herein shall include any
                        and all written and verbal information provided by either party in connection
                        herewith, which is not otherwise available from public sources, including but not
                        limited to patient protected health information as defined in 45 CFR 160.103. Each
                        party acknowledges the representations of the other party that such party's
                        Confidential Information is proprietary and valuable to that party and that any
                        disclosure or unauthorized use thereof may cause harm and loss. In consideration of
                        the disclosure of Confidential Information, each party agrees to receive and to treat
                        all Confidential Information on a strictly confidential and restricted basis and to
                        use all Confidential Information for the sole purpose of performance under this
                        Agreement.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Proprietary Rights of DATAVANT.</b> DATAVANT owns all right, title and interest in
                        and to the Software including, without limitation, all intellectual and proprietary
                        rights appurtenant thereto, and, except for the limited license granted to USER
                        herein, nothing in this Agreement shall be construed to transfer, convey, impair, or
                        otherwise adversely affect DATAVANT's ownership or proprietary rights therein or any
                        other DATAVANT information or materials, tangible or intangible, in any form and in
                        any medium. DATAVANT, www.datavanthealth.com, and the DATAVANT Health, LLC, logo, and
                        all other trademarks, service marks, and graphics used in connection with the
                        Software, are trademarks or registered trademarks of DATAVANT Health, LLC or DATAVANT
                        Health, LLC's licensors. Other trademarks, service marks, graphics and logos used in
                        connection with the Software and services may be the trademarks of other third
                        parties. USER's use of the Software and services grants no right or license to
                        reproduce or otherwise use any DATAVANT Health, LLC or third-party trademarks.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Add-Ons or Third-Party Software Used in Conjunction with the Software.</b> USER
                        understands and agrees that USER's use of any add-ons or third-party software in
                        conjunction with the Software, including but not limited to Google, Microsoft Outlook,
                        QuickBooks, Adobe Acrobat PDF Reader, or any other programs or software, is done
                        entirely at USER's own risk, and that DATAVANT makes no warranties that such Add-Ons
                        or Third-Party Software will function correctly, not interfere with USER's use of the
                        Software, or not result in damage to USER’S equipment, the Software, the add-ons or
                        third party Software used in conjunction with the Software, or any information or
                        documents contained on such equipment, Software, or Add-Ons or third-party Software.
                        The disclaimer of warranties and limitation on liabilities set forth in this Agreement
                        specifically apply to USER's use of any such Add-Ons or Third-Party Software in
                        conjunction with the Software.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>General Representation and Warranty.</b> USER represents and warrants that (i)
                        USER's use of the Software will be in strict accordance with all applicable laws and
                        regulations (including without limitation any local laws or regulations in USER'S
                        country, state, city, or other governmental area, regarding online conduct and
                        acceptable content, and including all applicable laws regarding the transmission of
                        technical data exported from the United States or the country in which USER resides)
                        and (ii) USER's use of the Software will not infringe or misappropriate the
                        intellectual property rights of DATAVANT or any third party.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Payment.</b> By signing up for a user account with DATAVANT for use of the Software
                        and services, USER agrees to pay DATAVANT any applicable fees such as but not limited
                        to, per page copy fees, duplication fees, reproduction fees, clerical labor, witness
                        fees, research fees, handling fees, base fees, shipping fees, electronic delivery
                        fees, certification fees, rush delivery fees, electronic data storage fees, no records
                        found fee, applicable taxes, and any other charges. The terms of any special billing
                        agreement with DATAVANT shall supersede any conflicting terms in these Terms and
                        Conditions. Absent any direct conflict the special billing agreement and these Terms
                        and Conditions shall both apply in conjunction. Standard payment terms with Datavant
                        require payment within 30 days unless addressed in a special billing agreement. All
                        records remain the property of DATAVANT until paid in full. Payment may be satisfied
                        by either bank draft or credit card only at this time. To the extent that any account
                        becomes past due, or the form of payment maintained on behalf of that account is
                        denied when charged for any payment toward the fees that become due, DATAVANT will
                        notify USER that USER's account is past due and will then attempt, fifteen days later,
                        to contact USER or use the form of payment USER has stored with USER's account. Any
                        amount not paid when due will be charged finance charges equal to one and one-half
                        percent (1.5%) per month or the highest rate permitted by applicable law, whichever is
                        less, determined and compounded daily from the date due until the date paid. DATAVANT
                        reserves the right to suspend the USER's accounts for failure to make payments as owed
                        and due on their account with DATAVANT, in which case no access to such an account
                        would be provided to the USER of their users. To the extent that a USER account
                        remains past due for more than sixty (60) consecutive days, the parties hereto
                        understand and agree that DATAVANT may, in its sole discretion and without notice to
                        the USER who created or maintains the account, cancel the account, and delete any
                        stored information, including documents or other information or content, maintained by
                        DATAVANT or on DATAVANT's servers in association with that user account. Any such
                        cancellation and/or deletion of information or content maintained by DATAVANT will in
                        no manner constitute a waiver of DATAVANT's right to all past due amounts as well as
                        any and all remaining quarterly payments that USER owes toward satisfaction of payment
                        as set forth in this Agreement.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>DISCLAIMER OF WARRANTIES.</b> THERE ARE NO REPRESENTATIONS OR WARRANTIES THAT APPLY
                        OR THAT ARE MADE TO USER IN ANY WAY IN CONNECTION WITH THE SOFTWARE, SERVICES OR THIS
                        AGREEMENT. TO THE MAXIMUM EXTENT PERMITTED BY LAW, DATAVANT DISCLAIMS ALL
                        REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE SOFTWARE, SERVICES AND USER'S
                        ACCESS TO AND USE THEREOF, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
                        THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR ANY
                        WARRANTIES OF TITLE, NON-INFRINGEMENT AND/OR ARISING FROM A COURSE OF DEALING OR USAGE
                        OF TRADE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE SOFTWARE AND SERVICES
                        ARE MADE AVAILABLE TO USER ON AN "AS-IS" AND "AS AVAILABLE" BASIS AND DATAVANT DOES
                        NOT GUARANTEE, WARRANT OR REPRESENT THAT THE SOFTWARE AND SERVICES SHALL MEET USER'S
                        REQUIREMENTS OR THAT USER'S USE, OPERATION OR RESULTS OF USE OF THE SOFTWARE AND
                        SERVICES SHALL BE UNINTERRUPTED, COMPLETE, RELIABLE, ACCURATE, CURRENT, ERROR-FREE,
                        FREE OF COMPUTER VIRUSES OR OTHERWISE SECURE. USER ASSUMES THE ENTIRE RISK OF
                        ACCESSING, UPLOADING TO, DOWNLOADING FROM, OPERATING, AND/OR USING THE SOFTWARE AND
                        SERVICES. TO CLARIFY FURTHER, DATAVANT AND ITS AFFILIATES MAKE NO WARRANTY THAT (1)
                        THE SOFTWARE AND SERVICES WILL MEET USER'S REQUIREMENTS OR RESULT IN REVENUES OR
                        PROFITS, (2) ANY ADD-ONS OR OTHER SOFTWARE OTHER THAN THE DATAVANT SOFTWARE WILL
                        FUNCTION WITH THE DATAVANT SOFTWARE, (3) THE RESULTS THAT MAY BE OBTAINED FROM THE USE
                        OF THE SOFTWARE OR SERVICES WILL BE ACCURATE, RELIABLE, OR DESIRABLE, AND/OR (4) THE
                        QUALITY OF ANY PRODUCTS, SERVICE, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED
                        BY USER THROUGH THE SOFTWARE AND SERVICES WILL MEET USER'S EXPECTATIONS. WHILE
                        DATAVANT WILL USE COMMERCIALLY REASONABLE EFFORTS TO PREVENT UNAUTHORIZED ACCESS TO
                        DATA ENTERED INTO OR MAINTAINED ON THE SOFTWARE, DATAVANT AND ITS SUPPLIERS MAKE NO
                        WARRANTY THAT SUCH INFORMATION OR DOCUMENTS WILL BE SECURE AGAINST UNAUTHORIZED ACCESS
                        OR OTHER SECURITY BREACHES. DATAVANT EXPRESSLY DISCLAIMS ANY REPRESENTATIONS OR
                        WARRANTIES THAT USER'S USE OF THE SOFTWARE OR SERVICES WILL SATISFY ANY STATUTORY OR
                        REGULATORY OBLIGATIONS, OR WILL ASSIST WITH, GUARANTEE OR OTHERWISE ENSURE COMPLIANCE
                        WITH ANY APPLICABLE LAWS OR REGULATIONS, INCLUDING BUT NOT LIMITED TO SUCH LAWS AS THE
                        HEALTH INSURANCE PORTABILITY AND ACCOUNTABILITY ACT OF 1996 ("HIPAA"), THE
                        SARBANES-OXLEY ACT OF 2002, OR OTHER FEDERAL OR STATE STATUTES OR REGULATIONS. USER IS
                        SOLELY RESPONSIBLE FOR ENSURING THAT USER'S USE OF THIS SOFTWARE, AS WELL AS RELATED
                        SERVICES OR CONTENT, IS IN ACCORDANCE WITH APPLICABLE LAW, RULES, AND REGULATIONS
                        INCLUDING ANY SUCH RULES OR REGULATIONS SET FORTH IN ANY APPLICABLE STATE'S TRIAL
                        RULES OR RULES OF ATTORNEY CONDUCT/ETHICS. ANY MATERIAL STORED, DOWNLOADED OR
                        OTHERWISE OBTAINED THROUGH THE USE OF THE SOFTWARE OR SERVICES IS STORED, DOWNLOADED
                        OR OBTAINED AT USER'S OWN DISCRETION AND RISK, AND USER WILL BE SOLELY RESPONSIBLE FOR
                        ANY DAMAGE TO USER'S COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF
                        ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY USER
                        FROM DATAVANT OR THROUGH, FROM OR RELATING TO THE SOFTWARE OR SERVICES SHALL CREATE
                        ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Errors and Corrections.</b> DATAVANT does not represent or warrant that the
                        DATAVANT website and Software will be error-free, free of viruses or other harmful
                        components, or that defects will be corrected as soon as practicable. DATAVANT does
                        not warrant or represent that the information available on or through the DATAVANT
                        portal will be correct, accurate, timely, or otherwise reliable. DATAVANT may make
                        improvements and/or changes to its features, functionality, or content at any time.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Amendments to the Website and this Agreement.</b> DATAVANT reserves the right, at
                        its sole discretion, to modify or replace any part of the website or this Agreement.
                        It is USER's responsibility to check the website and this Agreement periodically for
                        changes, which will be accessible through the footer of all pages of Smart Request.
                        USER's continued use of or access to the website and Software following the posting of
                        any changes constitutes acceptance of those changes. DATAVANT may also, in the future,
                        offer new services and/or features through the DATAVANT website or in conjunction with
                        the Software (including, the release of new tools and resources). Such new features
                        and/or services shall be subject to this Agreement.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Limitations on Liability.</b> In no event shall DATAVANT, its officers, directors,
                        employees, partners, parent company(ies), affiliates or suppliers be liable to USER or
                        any third party for any special, incidental, indirect, consequential or punitive
                        damages whatsoever, including those resulting from pass through fees from healthcare
                        providers or their business associates, loss of use, loss of data, lost profits,
                        whether or not foreseeable or if DATAVANT has been advised of the possibility of such
                        damages, or based on any theory of liability, including breach of contract or
                        warranty, negligence or other tortious action, or any other claim arising out of or in
                        connection with USER’S use of or access to the Software or any DATAVANT services.
                        Nothing in this Agreement shall limit or exclude DATAVANT's liability for intentional
                        misconduct of DATAVANT or its employees in the performance of their duties hereunder.
                        DATAVANT's aggregate liability and that of its affiliates and suppliers under or in
                        connection with this Agreement to one hundred United States dollars ($100). This
                        limitation will apply even if DATAVANT has been advised of the possibility of such
                        damages and notwithstanding any failure of essential purpose of any limited remedy.
                        USER further agrees that DATAVANT has no responsibility or liability for the deletion,
                        corruption, alteration of, or failure to store, any documents, messages or other
                        communications, or any other information, data, text (including but not limited to
                        names of files, databases, directories and groups/workgroups of the same), software,
                        music, sound, photographs, graphics and/or video provided by USER (collectively
                        referred to as "content") and created on, uploaded to, or maintained or transmitted by
                        DATAVANT or the Software. USER also agrees that DATAVANT will not be liable for any
                        (1) interruption of business, (2) access delays or access interruptions to the
                        Software; (3) unauthorized access to data entered in, or breach of any security
                        mechanisms utilized in, the Software; (4) any unauthorized access to the stored data;
                        or (5) any events beyond DATAVANT's reasonable control including but not limited to
                        USER's inability to access the Software due to issues relating to USER's internet
                        service, computer equipment or other computer software, applications or programs
                        (including web browsers) used to access the DATAVANT Software. The limitations and
                        exclusions in this section apply to the maximum extent permitted by applicable law in
                        USER’S jurisdiction. Some jurisdictions prohibit the exclusion or limitation of
                        liability for incidental, consequential or punitive damages. Accordingly, the
                        limitations and exclusions set forth above may not apply to USER.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Indemnification.</b> USER agrees to indemnify and defend DATAVANT, its parents,
                        subsidiaries, affiliates and/or their respective successors and assigns, officers,
                        directors, employees, agents, licensors, representatives, operational service
                        providers, and contractors (the "DATAVANT Indemnified Parties") against any and all
                        claims, demands and/or actions, losses, damages, costs and expenses (including
                        reasonable attorneys' fees), resulting from any claims, demands and/or actions related
                        to the performance or use of the services, website, and Software, for USER's
                        infringement, USER's misappropriation of patient's information or any violation of the
                        rights of any other party, violation or non-compliance with any law or regulation by
                        USER, failure by USER to pay any fees to a healthcare provider and/or its business
                        associate for the duplication and delivery of a requested medical record, any use,
                        alteration or export of the Software by USER, or otherwise in connection with this
                        Agreement or the Software, regardless of whether caused in whole or in part by
                        negligence of the DATAVANT Indemnified Parties. The DATAVANT Indemnified Parties
                        reserve the right to assume, at their own expense, the exclusive defense and control
                        of any claims or actions and all negotiations for settlement or compromise, and USER
                        agrees to fully cooperate with the DATAVANT Indemnified Parties upon DATAVANT's
                        request.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>TERMINATION.</b> DATAVANT HAS THE RIGHT TO TERMINATE THIS LICENSE AND USER'S RIGHT
                        TO USE THE SOFTWARE AND SERVICES AT ANY TIME FOR ANY REASON, INCLUDING IN THE EVENT
                        DATAVANT REASONABLY BELIEVES USER HAS FAILED TO COMPLY WITH ANY TERM OR CONDITION OF
                        THIS AGREEMENT. IF THIS LICENSE OR USER'S RIGHT TO USE THE SOFTWARE AND SERVICES
                        TERMINATES FOR ANY REASON, USER MUST STOP ALL USE OF THE SOFTWARE AND SERVICES AND
                        EITHER RETURN TO DATAVANT OR DESTROY THE SOFTWARE AND ALL COPIES OF THE SOFTWARE THAT
                        ARE IN USER'S POSSESSION OR CONTROL. IN THE CASE OF SUCH TERMINATION, ALL AMOUNTS OWED
                        TO DATAVANT AT THE TIME OF TERMINATION, IN ADDITION TO ANY ADDITIONAL FEES AS SET
                        FORTH HEREIN, WILL IMMEDIATELY BECOME DUE AND PAYABLE TO DATAVANT ON DEMAND. DATAVANT
                        MAY ASK USER TO CERTIFY COMPLIANCE WITH THE FOREGOING IN WRITING, AND USER AGREES TO
                        DO SO IF DATAVANT MAKES SUCH A REQUEST.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Governing Law; Jurisdiction.</b> By using any DATAVANT Service, USER agrees that
                        the Federal Arbitration Act, applicable federal law, and the laws of the state of
                        Arizona, without regard to principles of conflict of laws, will govern this Agreement
                        and any dispute of any sort that might arise between USER and DATAVANT.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Survival of Agreement.</b> All representations, warranties, limitations on
                        liability, exclusions of warranties, and any provisions governing the proprietary
                        nature of the Software, USER's duties upon termination of this Agreement, and the
                        indemnification and dispute resolution obligations of the parties, shall survive the
                        termination of this Agreement to the fullest extent allowed by law.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Pricing Structure.</b> I hereby acknowledge that this Agreement is solely between
                        DATAVANT and USER for medical record retrieval, storage, and delivery. USER
                        acknowledges and agrees to pay the fees charged by DATAVANT in consideration for
                        retrieval, storage, and delivery of the requested medical records: per page copy fees,
                        duplication fees, reproduction fees, clerical labor, witness fees, research fees,
                        handling fees, base fees, shipping fees, electronic delivery fees, certification fees,
                        rush delivery fees, electronic data storage fees, no record found fees, applicable
                        taxes, and any other charges. Records will automatically delete from the server after
                        the purchased time expires. USER understands and agrees that the electronic data
                        storage fees charged in relation to USER’S account with DATAVANT are fees agreed to in
                        contract for storing electronic medical records and are not fees regulated or covered
                        by any applicable state law.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>System Requirements.</b> Operational on a Windows or Mac platform using the
                        following supported browsers with minimum version requirements: Chrome 91, Safari
                        14.1, Edge 91, or Firefox 89.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <b>Authority.</b> By completing and submitting the registration for a DATAVANT Smart
                        Request account USER affirms that USER has the special and limited authority to
                        establish a DATAVANT Smart Request account for USER’S employer and its employees
                        located at the physical address provided on the request letter used to establish this
                        account. DATAVANT will deliver all reproduced medical records requested by USER’S
                        employer and its employees at the aforementioned address, through a secure internet
                        portal established and maintained by DATAVANT, subject to the terms and conditions
                        contained herein.
                    </UiKit.Chakra.ListItem>
                </UiKit.Chakra.OrderedList>
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>
    </UiKit.Chakra.VStack>
);
